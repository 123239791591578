@charset "utf-8";

$yellow: #ffc107;
$deepBlue: #123e74;
$brown: #998675;
$deepBrown: #534741;
//primary colors
$grayish-blue: hsl(237, 18%, 59%);
$soft-red: hsl(345, 95%, 68%);
//neutral colors
$white: hsl(0, 0%, 100%);
$dark-saturated: hsl(236, 21%, 26%);
$dark-blue: hsl(235, 16%, 14%);
$blackish-blue: hsl(234, 17%, 12%);
$grey: #333;
$white: #fff;
$black: #000;

html {
  overflow-x: hidden;
  padding: 0px;
  margin: 0px;
}
body {
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.5px;
  word-wrap: break-word;
  font-weight: 400;
  background-color: $white;
  color: $grey;
  overflow: hidden;
}
svg {
  fill: currentcolor;
  width: 15px;
  height: 15px;
}
img.svg {
  width: 15px;
  height: 15px;
}
::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #6f6f6f;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #6f6f6f;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #6f6f6f;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6f6f6f;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6f6f6f;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #6f6f6f;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 0px 40px;
  position: relative;
  clear: both;
  @media screen and (max-width: 767px) {
    padding: 0 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.4;
  font-family: 'Poppins';
  color: $deepBrown;
}

h1 {
  font-size: 45px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 30px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}
h6 {
  font-size: 16px;
}

.tm_all_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
}
.tm_all_wrap,
.tm_all_wrap * {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.tm_section {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: flex;
}
#preloader:before,
#preloader:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: $black;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
#preloader:after {
  left: auto;
  right: 0;
}
#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}
.loader_line:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: $white;
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  -o-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}
.loader_line:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #999999;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  -o-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  animation-delay: 2000ms;
}

@keyframes lineheight {
  0% {
    height: 0%;
  }
  100% {
    height: 100%;
  }
}

@keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(200%);
    -ms-transform: translateY(200%);
    -o-transform: translateY(200%);
    transform: translateY(200%);
  }
}

/*hiding all*/
.preloaded .loader_line:after {
  opacity: 0;
}
.preloaded .loader_line {
  opacity: 0;
  height: 100% !important;
}
.preloaded:before,
.preloaded:after {
  -webkit-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  -o-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  animation: preloadedzero 300ms ease-in-out 500ms forwards;
}
@keyframes preloadedzero {
  0% {
    width: 50%;
  }
  100% {
    width: 0%;
  }
}

/*---------------------------------------------------*/
/*	02) SHANE MOBILE MENU
/*---------------------------------------------------*/

.tm_mobile_menu {
  width: 100%;
  height: auto;
  position: relative;
  top: 0px;
  left: 0px;
  display: none;
  z-index: 10;

  .white-fill-bg.btn_sm {
    margin-top: 20px;
  }

  .topbar_inner {
    width: 100%;
    height: auto;
    float: left;
    clear: both;
    // background: $yellow;
    padding: 20px 0px;
    box-shadow: 0 10px 40px 0 rgba(62, 68, 125, 0.1);
  }

  .topbar_in {
    width: 100%;
    height: auto;
    clear: both;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.my_trigger .hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.my_trigger .hamburger-box {
  width: 30px;
  height: 18px;
  display: inline-block;
  position: relative;
  top: 4px;
}
.my_trigger .hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.my_trigger .hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 30px;
  height: 2px;
  background-color: #333333;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.my_trigger .hamburger-inner::before,
.my_trigger .hamburger-inner::after {
  content: '';
  display: block;
}
.my_trigger .hamburger-inner::before {
  top: -8px;
}
.my_trigger .hamburger-inner::after {
  bottom: -10px;
}
.my_trigger .hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.my_trigger .hamburger--collapse-r .hamburger-inner::after {
  top: -16px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.my_trigger .hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
    transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.my_trigger .hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
    transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.my_trigger .hamburger {
  padding: 0px;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}
.my_trigger .hamburger .hamburger-inner::before,
.my_trigger .hamburger .hamburger-inner::after,
.my_trigger .hamburger .hamburger-inner {
  background-color: $deepBrown;
  width: 26px;
  transition: all 0.3s ease;
}
.tm_mobile_menu .dropdown {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: $deepBrown;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transform: translateX(-100%);
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
}
.tm_mobile_menu .dropdown {
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }
}
.tm_mobile_menu .dropdown .dropdown_inner {
  width: 100%;
  height: 100vh;
  padding: 25px 0px;
}
.tm_mobile_menu .dropdown .dropdown_inner > ul {
  margin: 0px;
  list-style-type: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -50px;
}
.tm_mobile_menu .dropdown .dropdown_inner > ul li {
  margin: 0px;
  float: left;
  width: 100%;
}
.tm_mobile_menu .dropdown .dropdown_inner > ul li a {
  text-decoration: none;
  color: $white;
  display: inline-block;
  padding: 5px 0px;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 16px;
  @media only screen and (min-width: 768px) and (max-width: 1040px) {
    padding: 12px 0px;
    font-size: 25px;
    line-height: 42px;
  }
}
.tm_mobile_menu .logo img {
  max-width: 120px;
}
.close_menu {
  position: absolute;
  color: $white;
  font-weight: 500;
  font-size: 20px;
  right: 40px;
  top: 15px;
  z-index: 5;
  &::after {
    content: '';
    position: absolute;
    right: -22px;
    bottom: 12px;
    background-color: $white;
    width: 15px;
    height: 2px;
  }
}
/*---------------------------------------------------*/
/*	03) SHANE TOPBAR
/*---------------------------------------------------*/

.tm_topbar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  transition: all 0.3s ease;
}
.tm_topbar .topbar_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 50px;
  transition: all 0.5s ease;
  opacity: 0;
}
.tm_topbar .topbar_inner.opened {
  background-color: $white;
  padding: 20px 50px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  opacity: 1;

  .logo {
    opacity: 1;
  }
}
.tm_topbar .topbar_inner .logo a {
  display: inline-block;
}

.tm_topbar .menu ul {
  margin: 0px;
  list-style-type: none;
}
.tm_topbar .menu ul li {
  margin: 0px;
  display: inline-block;
}
.tm_topbar .menu ul li {
  margin: 0px 25px 0px 0px;
  display: inline-block;
}
.tm_topbar .menu ul li:last-child {
  margin-right: 0px;
}
.tm_topbar .menu ul li a {
  text-decoration: none;
  color: $black;
  font-weight: 500;
  font-family: 'Poppins';

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm_topbar .menu ul li a {
  color: #222;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 0;
    background: currentcolor;
    height: 2px;
    transition: 0.3s;
  }
}
.tm_topbar .menu ul li.current a {
  &::before {
    width: 100%;
  }
}

.social.social-default {
  display: inline-flex;
  list-style: none;
  li {
    display: flex;
    position: relative;
    z-index: 4;
    &:not(:last-child) {
      margin-right: 25px;
    }
    img.svg {
      width: 20px;
      height: 20px;
      filter: invert(1);
    }
  }
}
/*---------------------------------------------------*/
/*	04) SHANE HERO
/*---------------------------------------------------*/
.tm_hero {
  width: 100%;
  height: 100vh;
  float: left;
  clear: both;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    height: 100vh;
    padding: 240px 0;
  }
  .container {
    position: static;
  }
}
.slider-four {
  width: 100%;
  .tm_hero {
    .background {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 50%;
      height: 100vh;
      background: $white;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 480px) {
        background-size: cover;
        background-position-x: -50px;
      }
    }

    .image {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      left: 0px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
    .slick-arrow {
      display: none !important;
    }
    .slick-slide {
      right: 0px;
      top: 0px;
      bottom: 0px;
      background: $white;
    }
    .slick-slide img {
      max-height: 100vh;
      max-width: none;
      min-width: 100%;
    }

    @media screen and (max-width: 767px) {
      // padding: 270px 0;
      .slick-slide li {
        height: 80vh;
      }

      .slick-slide li img {
        transform: translateX(-58%);
      }
    }
  }
}
.tm_hero[data-style='one'] .background {
  clip-path: polygon(0% 100%, 21% 0%, 100% 0%, 100% 100%);
}
.tm_hero[data-style='two'] .background {
  clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%);
}
.tm_hero[data-style='three'] .background {
  clip-path: ellipse(70% 80% at 70% 50%);
}
.tm_hero .container {
  height: 100%;
}

.tm_hero .content {
  position: fixed;
  top: 45%;
  transform: translateY(-50%);
  text-align: center;
  margin: 0 auto;
  width: 92%;
  max-width: 1200px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: -1;

  @media (max-width: 996px) {
    top: 50%;
    transform: translateY(0);
    max-width: 700px;
  }

  @media (max-width: 480px) {
    top: 15%;
  }
}
.tm_hero .content.hide {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -500;
  // border: 1px solid red;
  // z-index: -1000;
}
.tm_hero .name_wrap {
  width: 100%;
  height: auto;
  clear: both;
  margin: 0 auto;
  margin-bottom: 0;
  text-align: center;
}
.tm_hero .job_wrap {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: center;
  // border: 1px solid red;
}
.tm_hero .content h3 {
  position: relative;
  font-size: 4.5rem;
  font-family: 'Montserrat';
  text-transform: uppercase;
  font-weight: 900;
  color: $black;
  line-height: 1.2;
  display: inline-block;
  overflow: hidden;
}
.tm_hero .logo_top {
  max-width: 500px;
  @media screen and (max-width: 767px) {
    max-width: 50%;
  }
}
.tm_hero .content h3 span {
  color: transparent;
  -webkit-text-stroke: 1px $black;
  padding-left: 2px;
  @media screen and (max-width: 767px) {
    -webkit-text-stroke: 1px $white;
  }
  span {
    font-size: 3rem;
  }
}
.tm_hero .content .job {
  font-weight: 500;
  font-size: 17px;
  font-family: 'Roboto';
  float: left;
  position: relative;
  overflow: hidden;
  color: #555;
  text-shadow: 3px 3px $brown;
  width: 100%;
  // border: 1px solid red;
  text-align: center;
}
.overlay_effect {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
}
.overlay_effect:after {
  position: absolute;
  content: '';
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: $white;
  z-index: 1;
}
.overlay_effect:before {
  position: absolute;
  content: '';
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
body.loaded .overlay_effect:before {
  z-index: 2;
  background-color: $black;
  animation: anim 2s cubic-bezier(0.77, 0, 0.175, 1) both;
}

body.loaded .overlay_effect:after {
  animation: anim2 2s cubic-bezier(0.77, 0, 0.175, 1) both;
}

@keyframes anim {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes anim2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.tm_down {
  position: absolute;
  z-index: 7;
  bottom: 50px;
  display: none;
}
.tm_down.loaded {
  display: block;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.tm_down[data-position='left'] {
  left: 0px;
}
.tm_down[data-position='right'] {
  right: 0px;
}
.tm_down[data-position='center'] {
  left: 50%;
  transform: translateX(-50%);
}
.tm_down .line_wrapper {
  position: absolute;
  width: 1px;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0px auto;
  opacity: 1;
  filter: alpha(opacity=100);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  visibility: visible;

  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}
.tm_down .line_wrapper {
  width: 1px;
  height: 50px;
  background: none;
  display: block;
}
.tm_down .line_wrapper:before {
  content: '';
  background-color: $black;
  width: 1px;
  height: 50%;
  display: block;
  top: 0;
  animation: scroll_down 2s ease-in-out infinite;
  @media screen and (max-width: 767px) {
    background-color: $white;
  }
}
@keyframes scroll_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 1;
    height: 50%;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.tm_down[data-skin='light'] .line_wrapper:before {
  background-color: $white;
}

/*---------------------------------------------------*/
/*	05) SHANE ABOUT
/*---------------------------------------------------*/
.tm_about {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 100px 0px 100px 0px;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media screen and (max-width: 992px) {
    padding: 60px 0px;
  }
  .about_inner {
    width: 100%;
    height: auto;
    clear: both;
    display: flex;
    align-items: center;
  }
  .left {
    width: 50%;
    padding-right: 30px;
    .image {
      position: relative;

      img {
        min-width: 100%;
        opacity: 0;
      }
    }
    .main {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      // box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
      @media screen and (max-width: 767px) {
        box-shadow: none;
      }
    }
    .text {
      width: 100%;
      height: auto;
      clear: both;
      float: left;
      margin-bottom: 40px;
      padding-top: 27px;
      color: $deepBrown;
    }
  }
  .right {
    width: 48%;
    padding-left: 50px;
  }
  .experience {
    span {
      display: block;
    }
    .year {
      font-size: 80px;
      font-weight: 800;
      font-family: 'Poppins';
      color: $white;
      margin-bottom: 3px;
      line-height: 1;
      font-style: italic;
    }
    .text {
      color: $white;
      font-style: italic;
      font-family: 'Poppins';
      opacity: 0.7;
    }
  }
}

.tm_map {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 120px 0px 0px 0px;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  text-align: center;

  h3,
  p {
    margin-bottom: 12px;
  }

  .map > div {
    height: 360px !important;
    width: 100% !important;
    @media screen and (max-width: 767px) {
      height: 290px !important;
    }
  }

  .container {
    padding: 0;
  }

  @media screen and (max-width: 992px) {
    padding: 60px 0px;
  }

  @media screen and (max-width: 767px) {
    padding: 0px;

    h3 {
      font-size: 1.25rem;
    }
  }
  .about_inner {
    width: 100%;
    height: auto;
    clear: both;
    display: flex;
    align-items: center;
  }
  .left {
    width: 35%;
    padding-right: 30px;
    .image {
      position: relative;

      img {
        min-width: 100%;
        opacity: 0;
      }
    }
    .main {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      // box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
      @media screen and (max-width: 767px) {
        box-shadow: none;
      }
    }
  }
  .right {
    width: 65%;
    .text {
      width: 100%;
      height: auto;
      clear: both;
      float: left;
      margin-bottom: 40px;
      padding-top: 27px;
      color: $deepBrown;
    }
  }
  .experience {
    span {
      display: block;
    }
    .year {
      font-size: 80px;
      font-weight: 800;
      font-family: 'Poppins';
      color: $white;
      margin-bottom: 3px;
      line-height: 1;
      font-style: italic;
    }
    .text {
      color: $white;
      font-style: italic;
      font-family: 'Poppins';
      opacity: 0.7;
    }
  }
}

.tm_title {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  h4 {
    font-weight: 700;
    font-family: 'Poppins';
  }
  span {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.05);
    text-transform: uppercase;
    padding: 4px 10px;
    font-weight: 600;
    font-size: 12px;
    color: $deepBrown;
    font-family: 'Poppins';
    letter-spacing: 0px;
    margin-bottom: 15px;
  }
}
.tm_button {
  width: 100%;
  height: auto;
  clear: both;
  float: left;

  a {
    text-decoration: none;
    color: $white;
    background-color: $brown;
    display: inline-block;
    padding: 13px 48px;
    border: 2px solid $brown;
    font-family: 'Poppins';
    transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
    cursor: pointer !important;
    @media screen and (max-width: 992px) {
      padding: 8px 25px;
    }
    &:hover {
      color: $grey;
      transform: translateY(-3px);
    }
  }
}
/*---------------------------------------------------*/
/*	06) SHANE PORTFOLIO
/*---------------------------------------------------*/

.tm_title .title_flex {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.tm_portfolio {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #f9f9f9;
  padding: 120px 0px 100px 0px;
  @media screen and (max-width: 992px) {
    padding: 60px 0px 40px;
  }
  .portfolio_filter {
    ul {
      margin: 0px;
      list-style-type: none;

      &.react-tabs__tab-list {
        li {
          text-decoration: none;
          color: $deepBrown;
          font-family: 'Poppins';
          font-weight: 500;
          cursor: pointer;
          position: relative;
          padding: 0 10px;
          display: inline-block;
          transition: 0.3s ease-in;
          &:not(:last-child) {
            margin-right: 15px;
            @media screen and (max-width: 767px) {
              margin-bottom: 5px;
              margin-right: 10px;
            }
          }
          @media screen and (max-width: 767px) {
            font-size: 13px;
          }

          &.react-tabs__tab--selected {
            background-color: $deepBrown;
            color: $white;
          }
        }
      }
    }
  }
}

.tm_portfolio .portfolio_list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-top: 89px;
}
.tm_portfolio .portfolio_list ul {
  margin: 0px 0px 0px -30px;
  list-style-type: none;
}
.tm_portfolio .portfolio_list li {
  margin: 0px 0px 5px 0px;
  width: 25%;
  float: left;
  padding-left: 5px;
  list-style-type: none;
  cursor: pointer;

  @media screen and (max-width: 766px) {
    width: 50%;
    margin: 0 0 2px 0;
    padding-left: 2px;
  }
}
.tm_portfolio .portfolio_list li .inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  overflow: hidden;
  &:hover {
    a {
      img {
        transform: scale(1.1);
      }
    }
    .mobile_title {
      transform: translateY(0);
    }
  }
}
.tm_portfolio .portfolio_list .image {
  position: relative;
}
.tm_portfolio .portfolio_list .inner img {
  min-width: 100%;
}
.tm_portfolio .portfolio_list .inner a img {
  &:after {
    padding-bottom: 100%;
  }
  object-fit: cover;
  transition: transform 1s cubic-bezier(0, 0, 0.2, 1);
}
ul.react-tabs__tab-list {
  position: absolute;
  right: 0;
  top: 65px;
  z-index: 2;
  @media screen and (max-width: 766px) {
    position: relative;
    top: 15px;
  }
}
.tm_portfolio .portfolio_list .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  clip-path: inset(0 0 0 0);

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.tm_portfolio .portfolio_list ul li .inner:hover .main {
  clip-path: inset(2% 2% 2% 2%);
}
.tm_portfolio_titles {
  white-space: nowrap;
  background: $white;
  font-size: 18px;
  padding: 5px 15px;
  font-family: 'Poppins';
  font-weight: 600;
  color: $black;
  position: fixed;
  z-index: 5;
  opacity: 0;
  visibility: hidden;

  .work__cat {
    position: absolute;
    background: $white;
    top: 100%;
    left: 0;
    margin-top: -10px;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    padding: 5px 15px;
  }
}
.tm_portfolio_titles.visible {
  opacity: 1;
  visibility: visible;
}
.entry {
  position: relative;
}
.tm_portfolio .mobile_title {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  z-index: 2;
  bottom: 0;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(100%);
  cursor: default;
  transition: transform 0.5s;

  h3 {
    font-size: 1rem;
    font-weight: 600;
    display: block;
    a {
      text-decoration: none;
      color: $deepBrown;

      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        height: 1px;
        background: $deepBrown;
        bottom: 0;
        z-index: 2;
        transition: 0.5s;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }

  span {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 0.75rem;
    color: $deepBrown;
    display: block;
  }
}

/*---------------------------------------------------*/
/*	07) SHANE SKILLS
/*---------------------------------------------------*/

.tm_skills {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 150px 0;
  @media screen and (max-width: 992px) {
    padding: 60px 0;
  }
}
.tm_skills .skills_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
}
.tm_skills .left {
  width: 50%;
  padding-right: 50px;
}
.tm_skills .text {
  padding-top: 27px;
  float: left;
}
.tokyo_progress {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.progress_inner {
  width: 100%;
  margin-bottom: 34px;
}
.progress_inner:last-child {
  margin-bottom: 0px;
}
.progress_inner > span {
  margin: 0px 0px 7px 0px;
  width: 100%;
  display: block;
  text-align: left;
  font-family: 'Poppins';
  font-weight: 500;
  color: #333;
}
.progress_inner span.number {
  float: right;
}
.progress_inner .background {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  min-width: 100%;
  position: relative;
  height: 4px;
}
.progress_inner .background .bar_in {
  height: 100%;
  background: $black;
  width: 0px;
  overflow: hidden;
}
.progress_inner .background .bar {
  width: 100%;
  height: 100%;
}
.progress_inner .background .bar.open {
  -webkit-animation: wow 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); /* Safari 4+ */
  -moz-animation: wow 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); /* Fx 5+ */
  animation: wow 1.5s cubic-bezier(0.165, 0.84, 0.44, 1); /* IE 10+ */
  width: 100%;
}

@-webkit-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@-moz-keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes wow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.tm_skills .right {
  width: 50%;
  padding-left: 50px;
}

/*---------------------------------------------------*/
/*	08) SHANE VIDEO
/*---------------------------------------------------*/

.tm_business_video {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tm_business_video {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
}
.tm_business_video .bg {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.tm_business_video .bg .image {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.tm_business_video .bg .overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.6);
}
.tm_business_video .content {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  margin: 0px auto;
  text-align: center;
  padding: 150px 0px;
  @media screen and (max-width: 992px) {
    padding: 75px 15px;
  }
}
.tm_business_video .rounded {
  width: 100px;
  height: 100px;
  display: block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
  background-color: $white;
  margin-bottom: 45px;
  cursor: pointer;
  transition: all 1s cubic-bezier(0, 0, 0.2, 1);
  @media screen and (max-width: 992px) {
    width: 75px;
    height: 75px;
    margin-bottom: 40px;
  }

  &:hover {
    transform: translateX(-50%) scale3d(1.15, 1.15, 1.15);
  }
}
.tm_business_video .rounded:before {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 13px 0 13px 26px;
  border-color: transparent transparent transparent $black;
  top: 50%;
  left: 50%;
  margin-left: 4px;
  transform: translate(-50%, -50%);
}
.tm_business_video .text {
  color: $white;
  font-family: 'Poppins';
  font-size: 30px;
  max-width: 600px;
  margin: 0px auto;
  margin-bottom: 50px;
  font-weight: 800;
}
.tm_video_button {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tm_video_button a,
.tm_video_button button {
  text-decoration: none;
  color: $white;
  background-color: transparent;
  display: inline-block;
  padding: 16px 50px;
  border: 2px solid $white;
  font-family: 'Poppins';
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
}
.tm_video_button button {
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 15px;
    padding: 10px 25px;
  }
}
.tm_video_button a:hover,
.tm_video_button button:hover {
  color: $black;
  background-color: $white;
  transform: translateY(-3px);
}

/*---------------------------------------------------*/
/*	09) SHANE PARTNERS
/*---------------------------------------------------*/

.tm_partners {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tm_partners .partners_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 90px 0px 80px;
  @media screen and (max-width: 992px) {
    padding: 35px 0px 25px;
  }
}
.tm_partners .partners_inner ul {
  margin: 0px;
  list-style-type: none;
}
.tm_partners .partners_inner ul li {
  margin: 0px;
  opacity: 0.5;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm_partners .partners_inner ul li {
  max-width: 200px;
  max-height: 200px;
}
.tm_partners .partners_inner ul li:hover {
  opacity: 1;
}

/*---------------------------------------------------*/
/*	10) SHANE TESTIMONIALS
/*---------------------------------------------------*/

.tm_testimonials {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: #f9f9f9;
  padding: 150px 0px;
  @media screen and (max-width: 992px) {
    padding: 60px 0px;
  }
}
.tm_testimonials .testimonials_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  justify-content: space-between;
}
.tm_testimonials .left {
  width: 50%;
  padding-right: 50px;
}
.tm_testimonials .right {
  width: 50%;
  padding-left: 50px;
}
.tm_testimonials .right ul {
  margin: 0px;
  list-style-type: none;
}
.tm_testimonials .right ul li {
  margin: 0px;
}
.tm_testimonials .right .texts p {
  margin-bottom: 40px;
}
.tm_testimonials .right .author {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
}
.tm_testimonials .right .author .image {
  width: 65px;
  height: 65px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
}
.tm_testimonials .right .author .main {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 100%;
  opacity: 0;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;

  transform: scale(1.5) translateZ(0);
}
.tm_testimonials .right .slick-slide.slick-active.slick-current .author .main {
  opacity: 1;
  transition: all 1s 0.3s ease;
  transform: scale(1) translateZ(0);
}
.tm_testimonials .right .short {
  padding-left: 25px;
}
.tm_testimonials .right .short h3 {
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  line-height: 1;
  position: relative;
}
.tm_testimonials .right .short .author {
  font-family: 'Montserrat';
  font-size: 18px;
  color: $black;
  font-weight: 600;
  margin-bottom: 0px;
  margin-bottom: 5px;
}
.tm_testimonials .right .short .job {
  font-weight: 400;
  display: block;
  font-size: 15px;
}
.tm_testimonials .right .short .author span,
.tm_testimonials .right .short .job span {
  position: relative;
  display: block;
  padding: 2px 0px;
  transform: translateY(102%);

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.tm_testimonials .right .slick-slide.slick-active.slick-current .short .author span {
  transform: translateY(0px);
  transition-delay: 0.3s;
}
.tm_testimonials .right .slick-slide.slick-active.slick-current .short .job span {
  transform: translateY(0px);
  transition-delay: 0.6s;
}

/*---------------------------------------------------*/
/*	11) SHANE NEWS
/*---------------------------------------------------*/

.tm_news {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 150px 0px 90px 0px;
  @media screen and (max-width: 992px) {
    padding: 60px 0px 10px;
  }
}
.tm_news .news_list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-top: 90px;
  @media screen and (max-width: 992px) {
    padding-top: 35px;
  }
}
.tm_news .news_list ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
}
.tm_news .news_list ul li {
  margin: 0px 0px 50px 0px;
  float: left;
  width: 33.3333%;
  padding-left: 50px;
}
.tm_news .news_list ul li .list_inner {
  width: 100%;
  height: 100%;
  clear: both;
  float: left;
  position: relative;
}
.tm_news .news_list ul li .image {
  position: relative;
  overflow: hidden;
}
.tm_news .news_list ul li .image img {
  min-width: 100%;
  opacity: 0;
}
.tm_news .news_list ul li .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transform: scale(1) translateZ(0);
  transition: all 1s cubic-bezier(0, 0, 0.1, 1);
  cursor: pointer;
}
.tm_news .news_list ul li .list_inner:hover .main {
  transform: scale(1.1) rotate(3deg);
}
.tm_news .news_list ul li .details {
  width: 100%;
  float: left;
  padding-right: 15px;
  padding-top: 32px;
}
.tm_news .news_list ul li .details .title {
  margin-bottom: 10px;
  text-decoration: none;
  color: $black;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.tm_news .news_list ul li .details .title:hover {
  color: $black;
}
.tm_news .news_list ul li .details .date {
  text-transform: uppercase;
  font-family: 'Poppins';
  font-size: 12px;
  color: #767676;
  font-style: italic;
}
.tm_news .news_list ul li .details .date a {
  text-decoration: none;
  color: #767676;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm_news .news_list ul li .details .date a:hover {
  color: $black;
}
.tm_news .news_list ul li .details .date span {
  position: relative;
  margin-left: 11px;
}
.tm_news .news_list ul li .details .date span:before {
  position: absolute;
  content: '';
  margin-top: 1px;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  right: 100%;
  background-color: #939393;
  width: 1px;
  height: 9px;
  margin-right: 7px;
}
.tm_news .description {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -11;
  display: none;
}
body.modal {
  overflow: hidden;
}

.tm_modalbox_news .close {
  position: fixed;
  left: 100%;
  top: 0px;
  margin-left: 40px;
  z-index: 20;
}
.tm_modalbox_news .close a {
  text-decoration: none;
  color: $white;
}
.tm_modalbox_news .close .svg {
  width: 50px;
  height: 50px;
}
.tm_modalbox_news .description_wrap {
  position: relative;
  width: 100%;
  float: left;
  padding: 50px;
}
.tm_modalbox_news .details {
  width: 100%;
  float: left;
  margin-bottom: 27px;
}
.tm_modalbox_news .description {
  width: 100%;
  float: left;
}
.tm_modalbox_news .description p {
  margin-bottom: 15px;
}
.tm_modalbox_news .description blockquote {
  font-style: italic;
  color: $black;
  border-left: 2px solid $black;
  padding-left: 20px;
  margin-bottom: 15px;
}
.tm_modalbox_news .description_wrap .image {
  position: relative;
  height: 450px;
  z-index: -1;
  margin-bottom: 36px;
  @media (max-width: 767px) {
    height: auto;
  }
}
.tm_modalbox_news .description_wrap .image img {
  min-width: 100%;
}
.tm_modalbox_news .description_wrap .image .main {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.tm_modalbox_news .details .title {
  font-weight: 600;
  margin-bottom: 8px;
  @media (max-width: 767px) {
    font-size: 22px;
  }
}
.tm_modalbox_news .date {
  text-transform: uppercase;
  font-family: 'Poppins';
  font-size: 12px;
  color: #767676;
  font-style: italic;
}
.tm_modalbox_news .date a {
  text-decoration: none;
  color: #767676;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm_modalbox_news .date a:hover {
  color: #e54b4b;
}
.tm_modalbox_news .date span {
  position: relative;
  margin-left: 11px;
}
.tm_modalbox_news .date span:before {
  position: absolute;
  content: '';
  margin-top: 0px;
  top: 50%;
  transform: translateY(-50%) rotate(15deg);
  right: 100%;
  background-color: #939393;
  width: 1px;
  height: 9px;
  margin-right: 7px;
}
.tm_modalbox_news .share {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
  padding-top: 14px;
}
.tm_modalbox_news .share span {
  font-family: 'Poppins';
  color: $black;
  font-weight: 600;
  display: inline-block;
  padding-right: 20px;
}
.tm_modalbox_news .share ul {
  margin: 0px;
  list-style-type: none;
}
.tm_modalbox_news .share ul li {
  margin: 0px 15px 0px 0px;
  display: inline-block;
}
.tm_modalbox_news .share ul li:last-child {
  margin-right: 0px;
}
.tm_modalbox_news .share ul li a {
  text-decoration: none;
  color: $black;
  img {
    &:hover {
      filter: opacity(0.8);
    }
  }
}
.tm_full_link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 4;
}

/*---------------------------------------------------*/
/*	12) SHANE TALK
/*---------------------------------------------------*/

.tm_talk {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding: 220px 0px 170px 0px;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: 150px 0;
  }
}
.tm_talk .talk_inner {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 3;
  overflow: hidden;
}
.tm_talk .text {
  margin-bottom: 35px;
  text-align: center;
}
.tm_talk .text h3 {
  color: $white;
  font-weight: 800;
  font-size: 72px;
}
.white-fill-bg {
  text-decoration: none;
  color: $black;
  background-color: $white;
  display: inline-block;
  padding: 15px 48px;
  border: 2px solid $white;
  font-family: 'Poppins';
  transition: all 0.5s cubic-bezier(0, 0, 0.1, 1);
  cursor: pointer;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    padding: 10px 25px;
    font-size: 15px;
  }

  &.fill-black {
    background: $black;
    color: $white;
    border-color: $black;
  }
  &.btn-outline {
    background: transparent;
    color: $white;
  }
  &.btn_sm {
    margin-top: 0;
    padding: 5px 25px !important;
    font-size: 15px !important;
    text-transform: uppercase;
    border-color: #c8c8c8 !important;
    &:hover {
      border-color: $white;
      transform: translateY(-3px);
    }
  }
}
.white-fill-bg {
  &:hover {
    color: $white;
    background-color: transparent;
    transform: translateY(-3px);
  }
  &.fill-black {
    &:hover {
      background-color: $white;
      color: $black;
    }
  }
  &.btn-outline {
    &:hover {
      background: $white !important;
      color: $brown !important;
      border-color: $white !important;
    }
  }
}
.tm_talk .background {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.tm_talk .overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

.tm_talk .shape {
  position: absolute;
  width: 100%;
  left: 0px;
  top: -13px;
  z-index: 2;
  transform: rotate(180deg);
}
.tm_talk .shape .svg {
  width: 100%;
  height: 100%;
}

/*---------------------------------------------------*/
/*	13) SHANE COPYRIGHT
/*---------------------------------------------------*/

.tm_copyright {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  background-color: $deepBrown;
  padding: 50px 0px;
}
.tm_copyright .inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: center;
}
.tm_copyright p {
  font-family: 'Poppins';
  color: $white;
}
.tm_copyright a {
  text-decoration: none;
  color: $white;
}

/*---------------------------------------------------*/
/*	14) SHANE MODALBOX CONTACT
/*---------------------------------------------------*/

.tm_mobalbox_contact .description_wrap {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  padding: 50px;
}
.tm_mobalbox_contact .title h3 {
  font-weight: 800;
}
.tm_mobalbox_contact .wrapper {
  width: 100%;
  height: auto;
  clear: both;
  display: flex;
  padding-top: 31px;
  margin-bottom: 50px;
}
.tm_mobalbox_contact .desc {
  max-width: 50%;
  float: left;
  padding-top: 95px;
}
.tm_mobalbox_contact .left {
  width: 50%;
  padding-right: 25px;
}
.tm_mobalbox_contact .fields {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tm_mobalbox_contact .fields .first {
  width: 100%;
  float: left;
}
.tm_mobalbox_contact .fields ul {
  margin: 0px;
  list-style-type: none;
}
.tm_mobalbox_contact .fields ul li {
  width: 100%;
  margin: 0px 0px 30px 0px;
  float: left;
}
.tm_mobalbox_contact .fields ul li input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: $white;
}
.tm_mobalbox_contact .fields ul li input:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.tm_mobalbox_contact .fields textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 120px;
  resize: none;
  background-color: $white;
}
.tm_mobalbox_contact .fields .last textarea:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.tm_mobalbox_contact .empty_notice {
  color: #f52225;
  margin-bottom: 7px;
  display: none;
  text-align: left;
  font-weight: 500;
}
.contact_form ul li span {
  color: #f52225;
  text-align: left;
  font-weight: 400;
}
.tm_mobalbox_contact .returnmessage {
  color: #3a00ff;
  margin-bottom: 7px;
  text-align: left;
  font-weight: 500;
}
.tm_mobalbox_contact .right {
  width: 50%;
  padding-left: 25px;
  position: relative;
  top: 6px;
}
.tm_mobalbox_contact .map_wrap {
  height: auto;
  .map > div {
    height: 360px !important;
    width: 100% !important;
    @media screen and (max-width: 767px) {
      height: 290px !important;
    }
  }
}
.tm_mobalbox_contact .short_info {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  padding-bottom: 20px;
}
.tm_mobalbox_contact .short_info > ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
}
.tm_mobalbox_contact .short_info > ul > li {
  margin: 0px 0px 30px 0px;
  float: left;
  width: 33.3333%;
  padding-left: 50px;
  text-align: center;
}
.tm_mobalbox_contact .short_info > ul > li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  background-color: $black;
  padding: 50px 15px;
}
.tm_mobalbox_contact .short_info > ul > li p {
  font-family: 'Poppins';
  font-weight: 500;
  color: $white;
}
.tm_mobalbox_contact .short_info > ul > li a {
  text-decoration: none;
  color: $white;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm_mobalbox_contact .short_info > ul > li a:hover {
  color: $white;
}
.tm_mobalbox_contact .short_info > ul > li .svg {
  width: 30px;
  height: 30px;
  margin-bottom: 12px;
  filter: invert(1);
}
.tm_mobalbox_contact .social {
  margin: 0px;
  list-style-type: none;
  position: relative;
  top: 11px;
}
.tm_mobalbox_contact .social li {
  margin: 0px 15px 0px 0px;
  display: inline-block;
  width: auto !important;
}
.tm_mobalbox_contact .social li:last-child {
  margin-right: 0px;
}
.tm_mobalbox_contact .social li a {
  text-decoration: none;
  color: $white;
}
.tm_mobalbox_contact .social li .svg {
  width: 15px !important;
  height: 15px !important;
}
.tm_mobalbox_contact .share > span {
  display: none;
}
.tm_mobile_menu .white-fill-bg.btn_sm {
  @media screen and (min-width: 768px) and (max-width: 1040px) {
    padding: 5px 40px !important;
  }
}
.tm_mobile_menu .dropdown {
  &.active {
    ul {
      li {
        &:nth-child(1) {
          animation: 600ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(2) {
          animation: 700ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(3) {
          animation: 800ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(4) {
          animation: 900ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(5) {
          animation: 1000ms ease-in-out 0s normal none 1 running customThree;
        }
        &:nth-child(6) {
          animation: 1100ms ease-in-out 0s normal none 1 running customThree;
        }
      }
    }
    .close_menu {
      animation: 1.2s ease-in-out 0s normal none 1 running customThree;
    }
  }
}
.social-default {
  opacity: 0;
}
.loaded {
  .social-default {
    opacity: 1;
    animation: 4s ease-in-out 0s normal none 1 running customFour;
  }
}
.loaded .home-three {
  .overlay_effect:after {
    background-color: #f9f9f9;
  }
}
.loaded .home-four {
  .overlay_effect:after {
    background-color: #003a55;
  }
  .overlay_effect:before {
    background-color: $white;
  }
}
@keyframes customFour {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.customFour {
  -webkit-animation-name: customThree;
  animation-name: customThree;
}
@keyframes customThree {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.customThree {
  -webkit-animation-name: customThree;
  animation-name: customThree;
}
/*---------------------------------------------------*/
/*	15) SHANE CURSOR
/*---------------------------------------------------*/

.mouse-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  visibility: hidden;
}
.cursor-inner {
  margin-left: -3px;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  z-index: 100;
  background-color: $black;
  -webkit-transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out, margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.cursor-inner.cursor-hover {
  margin-left: -40px;
  margin-top: -40px;
  width: 80px;
  height: 80px;
  background-color: $black;
  opacity: 0.3;
}
.cursor-outer {
  margin-left: -15px;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  border: 2px solid $black;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 100;
  opacity: 0.5;
  -webkit-transition: all 0.08s ease-out;
  transition: all 0.08s ease-out;
}
.cursor-outer.cursor-hover {
  opacity: 0;
}
.tm_all_wrap[data-magic-cursor='hide'] .mouse-cursor {
  display: none;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -1111;
}

/*---------------------------------------------------*/
/*  SHANE SETTINGS
/*---------------------------------------------------*/

.tm_settings {
  position: fixed;
  z-index: 200;
  top: 15%;
  right: -200px;
  width: 200px;
  background-color: rgba(0, 0, 0, 1);
  padding: 40px 20px 33px 30px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tm_settings.opened {
  right: 0px;
}
.tm_settings .wrapper {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tm_settings .icon {
  position: absolute;
  top: 0px;
  right: 100%;
  background-color: $black;
  padding: 12px 12px 4px 12px;
}
.tm_settings .icon .svg {
  color: $white;
  width: 25px;
  height: 25px;
  animation: fa-spin 2s infinite linear;
}
.tm_settings .link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.tm_settings .title {
  color: $white;
  font-family: 'Montserrat';
  font-weight: 600;
  position: relative;
  display: inline-block;
}
.tm_settings .title:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  left: 0px;
  bottom: 0px;
}
.tm_settings .colors {
  margin: 0px 0px 22px 0px;
  list-style-type: none;
  padding-top: 32px;
}
.tm_settings .colors li {
  margin: 0px;
  display: inline-block;
}
.tm_settings .colors li a {
  text-decoration: none;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
.tm_settings .direction {
  list-style-type: none;
  margin: 0px;
}
.tm_settings .direction li {
  margin: 0px 20px 0px 0px;
  display: inline-block;
}
.tm_settings .direction li a {
  opacity: 0.4;
  text-decoration: none;
  color: $white;
}
.tm_settings .direction li a.active {
  opacity: 1;
}
.tm_settings .direction li .svg {
  width: 25px;
  height: 25px;
}
.tm_settings .colors li.bl {
  display: inline-block;
}
.tm_settings .colors li.wh {
  display: none;
}
body.dark .tm_settings .colors li.bl,
.tm_settings.changed .colors li.bl {
  display: none;
}
body.dark .tm_settings .colors li.wh,
.tm_settings.changed .colors li.wh {
  display: inline-block;
}
body.dark .tm_settings,
.tm_settings.changed {
  background-color: $white;
}
body.dark .tm_settings .icon,
.tm_settings.changed .icon {
  background-color: $white;
}
body.dark .tm_settings .icon .svg,
.tm_settings.changed .icon .svg {
  color: $black;
}
body.dark .tm_settings .title,
.tm_settings.changed .title {
  color: $black;
}
body.dark .tm_settings .title:before,
.tm_settings.changed .title:before {
  background-color: rgba(0, 0, 0, 0.5);
}
body.dark .tm_settings .direction li a,
.tm_settings.changed .direction li a {
  color: $black;
}
body.dark .tm_settings .cursor li:nth-child(1) a,
.tm_settings.changed .cursor li:nth-child(1) a {
  border-color: $black;
}
body.dark .tm_settings .cursor li:nth-child(1) a:before,
.tm_settings.changed .cursor li:nth-child(1) a:before {
  background-color: $black;
}
body.dark .tm_settings .cursor li .svg,
.tm_settings.changed .cursor li .svg {
  color: $black;
}
.tm_settings .cursor {
  margin: 0px;
  list-style-type: none;
  padding-top: 15px;
}
.tm_settings .cursor li {
  margin: 0px 20px 0px 0px;
  display: inline-block;
}
.tm_settings .cursor li a {
  text-decoration: none;
  color: $white;
  opacity: 0.4;
  font-weight: 600;
}
.tm_settings .cursor li a.showme {
  opacity: 1;
}
.tm_settings .cursor li:nth-child(1) a {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid $white;
  position: relative;
  display: inline-block;
}
.tm_settings .cursor li:nth-child(1) a:before {
  position: absolute;
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: $white;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.tm_settings .cursor li .svg {
  width: 25px;
  height: 25px;
  color: $white;
}
.tm_settings .cursor li:nth-child(2) {
  position: relative;
  top: -2px;
}
.tm_settings .effect {
  margin: 0px;
  list-style-type: none;
  padding-top: 15px;
  padding-bottom: 25px;
}
.tm_settings .effect li {
  margin: 0px 0px 5px 0px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  background-color: $white;
  opacity: 0.4;
}
.tm_settings .effect li a {
  text-decoration: none;
  color: $black;
  font-size: 13px;
  font-weight: 600;
  font-family: 'Montserrat';
  display: block;
  position: relative;
  top: 2px;
}
.tm_settings .effect li.selected {
  background-color: $white;
  opacity: 1;
}

/*---------------------------------------------------*/
/*	SHANE INTRO
/*---------------------------------------------------*/

.tm_intro {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tm_intro .hero {
  width: 100%;
  height: 100vh;
  float: left;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  @media screen and (max-width: 767px) {
    height: 65vh;
  }
}
.tm_intro .hero:before {
  position: absolute;
  content: '';
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-repeat: repeat;
  opacity: 1;
  z-index: 2;
}
.tm_intro .hero:after {
  position: absolute;
  content: '';
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.tm_intro .hero .content {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  @media screen and (max-width: 767px) {
    width: 250px;
  }
}
.x0.x1.x2 {
  margin: 5px 0;
  display: block;
  @media screen and (max-width: 767px) {
    margin: 0;
  }
}
.tm_intro .hero h3 {
  color: $white;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 35px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.tm_intro .hero p {
  font-family: 'Poppins';
  font-weight: 500;
  color: $white;
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 40px;
}
.purchase_button a {
  text-decoration: none;
  color: $black;
  background-color: $white;
  display: inline-block;
  padding: 13px 48px;
  border: 2px solid $white;
  font-family: 'Poppins';
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.purchase_button a:hover {
  background-color: transparent;
  color: $white;
}
.tm_intro .intro_title {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  text-align: center;
  padding: 100px 0px 70px 0px;
  @media screen and (max-width: 767px) {
    padding-bottom: 60px;
  }
}
.tm_intro .intro_title h3 {
  font-weight: 800;
  font-size: 40px;
}
.tm_intro .demo_list {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  margin-bottom: 60px;
}
.tm_intro .demo_list .inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}
.tm_intro .demo_list ul {
  margin: 0px 0px 0px -50px;
  list-style-type: none;
}
.tm_intro .demo_list ul li {
  margin: 0px 0px 20px 0px;
  float: left;
  width: 50%;
  padding-left: 50px;
}
.tm_intro .demo_list ul li .list_inner {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
  position: relative;
  text-align: center;
  transition: all 0.3s ease;
  top: 0px;
}
.tm_intro .demo_list ul li .list_inner img {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.tm_intro .demo_list ul li .list_inner:hover {
  top: -10px;
}
.tm_intro .demo_list ul li .list_inner h3 {
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0px;
}

// preview page css
.section-separaton {
  width: 100%;
  background: #191919;
  float: left;
  padding-bottom: 70px;
}

.tm_feature {
  .container {
    &.fluid {
      max-width: 100%;
      padding: 0 135px;
      @media screen and (min-width: 280px) and (max-width: 1919px) {
        padding: 0 15px;
      }
    }
  }
  .intro_title h3 {
    color: $white;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    @media screen and (max-width: 1199px) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    @media screen and (max-width: 991px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media screen and (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .single-features {
    height: 100%;
    padding: 30px 40px;
    border-radius: 5px;
    transition: 0.3s;
    text-align: center;

    img {
      width: 55px;
      height: 55px;
      margin-bottom: 30px;
      background: hsla(0, 0%, 100%, 0.1);
      padding: 10px;
      border-radius: 50%;
      transition: 0.5s;
    }

    &:hover {
      transform: skewY(3deg);
      box-shadow: 0px 2px 30px 0px rgba(213, 55, 102, 0.05);
      img {
        background: $white;
      }
    }

    h4 {
      font-size: 22px;
      line-height: 1.2;
      color: $white;
      font-weight: 600;
      margin-bottom: 18px;
    }
    p {
      color: rgba(198, 201, 216, 0.75);
      font-size: 18px;
    }
  }
}

.shane_purchase_banner {
  padding: 150px 0;
  text-align: center;
  background-repeat: repeat;
  background-position: center;
  background-size: contain;
  @media screen and (max-width: 767px) {
    padding: 100px 0;
  }

  h2 {
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 30px;
  }

  .tm_button {
    float: none;
  }
}
.countdown__container {
  width: 100%;
  display: flex;
  height: auto;
  justify-content: space-around;
  border: 1px solid rgba(255, 255, 255, 0.01);
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 767px) {
    max-width: 100%;
  }
  .countdown__card {
    width: 20%;
    .countdown__card__bg {
      position: relative;
      height: 0;
      padding-bottom: 100%;
      // background-color: $dark-saturated;
      // box-shadow: 0px 8px 0px 1px $blackish-blue;
      border-radius: 10px;
      transition: all 0.175s linear;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(50% - 1vh);
        width: 6px;
        height: 12px;
        border-radius: 0 15px 15px 0;
        // background-color: $blackish-blue;
        z-index: 10;
      }
      &::after {
        content: '';
        position: absolute;
        right: 0;
        top: calc(50% - 1vh);
        width: 6px;
        height: 12px;
        border-radius: 15px 0 0 15px;
        // background-color: $blackish-blue;
        z-index: 10;
      }
      .countdown__card__number {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        place-content: center;
        place-items: center;
        font-size: 3.5rem;
        color: $soft-red;
        text-shadow: 2px 2px $white;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 50%;
          opacity: 0.35;
          z-index: 10;
        }
      }

      @media (max-width: 767px) {
        .countdown__card__number {
          font-size: 3rem;
        }
      }
    }
  }
  .countdown__card__label {
    // padding-top: 1.5rem;
    text-transform: uppercase;
    font-size: 0.875rem;
    letter-spacing: 5px;
    color: $white;
    text-shadow: 2px 2px $deepBrown;

    @media (max-width: 767px) {
      font-size: 0.5rem;
    }
  }
}
.rotate {
  transform: rotateX(360deg);
}

/*---------------------------------------------------*/
/*	16) SHANE MEDIA QUERIES (FOR SMALL DEVIVES)
/*---------------------------------------------------*/
@media (max-width: 1040px) {
  .tm_topbar {
    display: none;
  }
  .tm_mobile_menu {
    display: block;
  }
  .tm_portfolio_titles {
    display: none;
  }
  .tm_portfolio .mobile_title {
    display: block;
  }
  .tm_hero .content h3 {
    font-size: 35px;
  }

  .tm_portfolio .portfolio_list ul li {
    width: 50%;
  }
  .tm_title .title_flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .tm_title .portfolio_filter {
    padding-top: 78px;
  }
  .tm_portfolio .portfolio_list {
    padding-top: 40px;
  }
  .tm_skills .skills_inner {
    flex-direction: column;
  }
  .tm_skills .left {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
  .tm_skills .right {
    padding-left: 0px;
    width: 100%;
  }
  .tm_testimonials .testimonials_inner {
    flex-direction: column;
  }
  .tm_testimonials .left {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 50px;
  }
  .tm_testimonials .right {
    padding-left: 0px;
    width: 100%;
  }
  .tm_news .news_list ul li {
    width: 50%;
  }
  .tm_talk .text h3 {
    font-size: 50px;
    line-height: 1.2;
  }
  .tm_modalbox_news .description_wrap {
    padding: 50px 20px;
  }
  .tm_mobalbox_contact .description_wrap {
    padding: 50px 20px;
  }
  .tm_mobalbox_contact .wrapper {
    flex-direction: column;
  }
  .tm_mobalbox_contact .left {
    width: 100%;
    padding-right: 0px;
    margin-bottom: 50px;
  }
  .tm_mobalbox_contact .right {
    width: 100%;
    padding-left: 0px;
  }
  .tm_mobalbox_contact .short_info ul {
    margin: 0px;
  }
  .tm_mobalbox_contact .short_info ul li {
    width: 100%;
    padding-left: 0px;
  }
  .tm_hero[data-style='one'] .background {
    -webkit-clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%);
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 100% 100%);
  }

  .tm_hero .image {
    background-position: 51% 100%;
  }
}
@media (max-width: 480px) {
  .tm_hero .content {
    padding-right: 0px;
    width: 90%;
    z-index: 5;
    h3 {
      color: $white;
    }
    .job {
      color: #dedede;
      margin-top: 5px;
      margin-left: 5%;
      max-width: 90%;
    }
  }
  .tm_hero {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }
  .tm_about .about_inner {
    flex-direction: column;
  }
  .tm_about .left {
    padding-right: 0px;
    width: 100%;
    margin-bottom: 50px;
  }
  .tm_about .right {
    padding-left: 0px;
    width: 100%;
  }
  .tm_portfolio .portfolio_list ul {
    margin: 0px;
  }
  .tm_portfolio .portfolio_list ul li {
    padding: 0px;
    width: 100%;
    margin-bottom: 20px;
  }
  .tm_news .news_list ul {
    margin: 0px;
  }
  .tm_news .news_list ul li {
    padding: 0px;
    width: 100%;
  }
  .tm_portfolio .tm_title {
    margin-bottom: 30px;
  }
  .tm_intro .demo_list ul {
    margin: 0px;
  }
  .tm_intro .demo_list ul li {
    width: 100%;
    padding-left: 0px;
  }
}

body.intro #preloader:before,
body.intro #preloader:after {
  background-color: $white;
}
body.intro .loader_line:before {
  background-color: $black;
}
body.intro .loader_line:after {
  background-color: #a8a8a8;
}

.modal-video-close-btn {
  cursor: pointer;
}

.bg_image_props {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.scroll_up {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: $white;
  color: $black;
  width: 45px;
  height: 45px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 1s cubic-bezier(0, 0, 0.1, 1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  img {
    width: 16px;
  }
  &:hover {
    transform: translateY(-5px);
  }
}
.job_wrap {
  &.sociallo {
    margin-top: 14px;
  }
  .social-default {
    margin-top: 0;
  }
}
// portfolio animation
.portfolio_list.has-effect .react-tabs__tab-panel--selected {
  -webkit-animation: 800ms ease-in-out 0s normal none 1 running customOne;
  animation: 800ms ease-in-out 0s normal none 1 running customOne;
}

@keyframes customOne {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.customOne {
  animation-name: customOne;
}

.social-menu {
  position: absolute;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
}
.social-menu .share {
  display: flex;
  align-items: center;
  span {
    display: none;
  }
}
.social-menu .share .social {
  flex-direction: row !important;
  li {
    margin: 0 10px !important;
  }
  img {
    filter: invert(1);
    width: 20px;
    height: 20px;
  }
}

.slider-social {
  list-style: none;
  display: flex;
  align-items: center;
}

// home version three
.home-three {
  .tm_topbar {
    position: sticky;
    background: $white;
    border-bottom: 1px solid #efefef;
  }
  .tm_hero {
    height: calc(100vh - 80px);
    background: #f9f9f9;
    @media screen and (max-width: 1040px) {
      height: calc(60vh - 80px);
    }
  }
}
.position-relative {
  position: relative;
  overflow: hidden;
}
.slider-four {
  .white-fill-bg {
    margin-top: 35px;
    position: relative;
    overflow: hidden;
  }
  .btn-outline {
    background: $brown;
    color: $white;
  }
}
.btn-medium {
  padding: 12px 41px;
  @media screen and (max-width: 768px) {
    padding: 8px 25px;
  }
}
.tm_topbar .white-fill-bg.btn-outline {
  margin-left: 10px;
}
.modal-video-close-btn {
  @media screen and (min-width: 1041px) {
    top: 0 !important;
    right: -40px !important;
  }
}
.home-four {
  .tm_hero .background {
    width: 100% !important;
  }
  .tm_hero .image {
    background-position: 100% 12%;
  }
  @media (max-width: 1040px) {
    .tm_hero .image {
      background-position: 71% 100%;
    }
  }

  .tm_hero[data-style='one'] .background {
    -webkit-clip-path: none !important;
    clip-path: none !important;
  }
  .tm_hero .content h3 span {
    -webkit-text-stroke: 1px $white;
  }
  .tm_hero .content h3 {
    color: $white;
    position: relative;
    overflow: hidden;
  }
  .tm_topbar .topbar_inner .logo a img {
    max-width: 150px;
    filter: brightness(100);
  }

  .tm_topbar .menu ul li a {
    color: $white;
    text-shadow: 1px 1px $brown;
  }
  .tm_topbar .menu ul li.current a {
    color: #efefef;
  }
  .tm_topbar .topbar_inner.opened {
    background-color: $brown;
    opacity: 0.86;
    li.current a {
      color: $white;
    }
    a {
      color: $white;
    }
  }
  .tm_topbar .topbar_inner {
    max-width: 1200px;
    margin: 0 auto;
  }
  .tm_hero .content .job {
    font-weight: 700;
    font-size: 35px;
    font-family: 'Montserrat';
    color: $white;
    padding: 8px 0px 10px;
    @media (max-width: 1040px) {
      font-size: 20px;
      font-weight: 600;
      padding: 8px 0 0;
    }
  }
  .tm_hero .content {
    z-index: 3;
  }

  .tm_hero .content h3 {
    line-height: 1.3;
  }
  @media screen and (max-width: 767px) {
    .tm_hero .content h3 {
      font-size: 40px;
    }
    .tm_hero .content .job {
      font-size: 25px;
      padding-top: 0;
    }
  }
}
.home-one {
  .tm_hero .image {
    background-position: center right;
    @media screen and (max-width: 1040px) {
      background-position: 81% 100%;
    }
  }
  .tm_hero {
    background: rgba(253, 253, 253, 1);
  }
  .white-fill-bg.btn_sm {
    border-color: #555 !important;
    &:hover {
      border-color: $white !important;
    }
  }
}
.home-one,
.home-two,
.home-three,
.home-three {
  .white-fill-bg.btn_sm {
    border-color: #555 !important;
    &:hover {
      border-color: $black !important;
      background: $black !important;
      color: $white !important;
    }
  }
}

.slider-two {
  .social.social-default li img.svg {
    filter: invert(0.3);
    @media screen and (max-width: 767px) {
      filter: invert(1);
      width: 18px;
      height: 18px;
    }
    &:hover {
      filter: invert(0);
      @media screen and (max-width: 767px) {
        filter: invert(0.9);
      }
    }
  }
}
// particle css
.frame-layout__particles {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
  > div {
    width: 100%;
    height: 100%;
  }
}

// loop text
.loop-text {
  color: $white !important;
  text-transform: capitalize;
  font-weight: 800;
  @media screen and (max-width: 1040px) {
    font-weight: 700;
    font-size: 2rem;
  }
}

.social-default {
  margin-top: 14px;
  height: 40px;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
  li {
    align-items: center;
    position: relative;
  }
  .svg {
    transition: 0.3s;
    &:hover {
      transform: translateY(-5px);
    }
  }
}

.shane_error_page {
  h1,
  h2 {
    font-weight: 900;
    font-size: 125px;
    font-family: 'Montserrat';
    line-height: 1;
    letter-spacing: 5px;
    @media screen and (max-width: 768px) {
      font-size: 80px;
    }
  }
  &.shane_error_page {
    background: transparent;
  }
  &.tm_intro .hero {
    &::after {
      display: none;
    }
    p {
      color: $black;
      padding-bottom: 45px;
      @media screen and (max-width: 768px) {
        padding-bottom: 30px;
        font-size: 18px;
        padding-top: 20px;
      }
    }
    .white-fill-bg {
      border-color: $black !important;
      color: $black !important;
      font-weight: 500;
      &:hover {
        background: $black !important;
        color: $white !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .prev-logo {
    height: 45px;
  }
  .logo {
    img {
      max-width: 80px;
    }
  }
}
.demo_list .container {
  height: 100vh;
}
.modal-video {
  background-color: rgba(0, 0, 0, 0.75) !important;
}
