// /* total width */
body {
  scrollbar-width: 8px;
  scrollbar-height: 8px;
  scrollbar-base-color: #efefef;
  scrollbar-face-color: #666666;
  scrollbar-3dlight-color: #666666;
  scrollbar-highlight-color: #666666;
  scrollbar-track-color: #efefef;
  scrollbar-arrow-color: #666666;
  scrollbar-shadow-color: #666666;
  scrollbar-dark-shadow-color: #666666;
}
// body::-webkit-scrollbar {
//   background-color: #fff;
//   width: 8px;
//   height: 8px;
// }

// /* background of the scrollbar except button or resizer */
// body

// /* scrollbar itself */
// body

// body::-webkit-scrollbar-thumb:horizontal {
//   background-color: #babac0;
//   border-radius: 8px;
// }

// /* set button(top and bottom of the scrollbar) */
// body::-webkit-scrollbar-button {
//   display: none;
// }

::-webkit-scrollbar {
  background-color: #fff;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #dedede;
  border-radius: 16px;
  border: 2px solid #fff;
}

/* ie scrollbar color properties */
